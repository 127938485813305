import Layout from "components/Layout";
import styled, { createGlobalStyle } from "styled-components";
import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { AuthContext } from "contexts/AuthProvider";
import { sanitizeRedirectPath } from "utils";

import Divider from "@/atoms/Divider";
import { SocialButtonsContainer } from "components/authenticate/SocialButtonsContainer";
import LoginForm from "@/account/LoginForm";
import Card from "@/atoms/account/Card";
import { LINKS } from "globalConfig";
import { makeUrl } from "utils";
import { getAuth } from "lib/api/account";
import { useRouter } from "next/router";
import Head from "next/head";
import StickyHeaderLayout from "components/Layout/StickyHeaderLayout";
import { GetServerSidePropsContext } from "next";
import { LinkNext, colors } from "@literati/public-ui-shared-react";

const DEFAULT_NEXT = "/account/home/";

const Container = styled.div`
  max-width: 324px;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  flex-grow: 1;
`;

interface MainContainerProps {
  $fullScreen: boolean;
  $isModal: boolean;
}

const MainContainer = styled.div<MainContainerProps>`
  flex: 1 auto;
  padding: 2rem 0;
  transition: 0.2s background ease;
  background: ${({ $fullScreen, theme }) =>
    $fullScreen ? theme.colors["mint3"] : "white"};
  height: ${({ $fullScreen }) => ($fullScreen ? "100vh" : "auto")};
`;

const GlobalStyle = createGlobalStyle`
  main {
    display: flex;
  }
`;

export const CustomCard = styled(Card)`
  padding: ${({ $isModal }) => ($isModal ? "0" : "2rem;")};
  border: ${({ $isModal }) => ($isModal ? "none" : undefined)};
  box-shadow: ${({ $isModal }) => ($isModal ? "none" : undefined)};
`;

const CustomCardHeading = styled.div`
  font-weight: 500;
  text-align: center;
  margin-bottom: 1.5rem;
  color: ${({ theme }) => theme.colors["ink-black"]};
`;

const SubheadText = styled.p`
  margin: 1rem 0 2rem 0;
  text-align: center;
`;

export const CustomCardContainer = ({
  children,
  label = "Log in to your Literati account",
  $isModal = false,
}: {
  children: ReactNode;
  label?: string;
  $isModal?: boolean;
}) => {
  return (
    <CustomCard $isModal={$isModal}>
      <CustomCardHeading>{label}</CustomCardHeading>
      {children}
    </CustomCard>
  );
};

const CardlessHeading = styled.h1<{ $isModal?: boolean }>`
  font-family: hoefler;
  font-size: 24px;
  text-align: center;
  color: ${({ theme }) => theme.colors["ink-black"]};
  margin: 18px 0;
  margin-top: ${({ $isModal }) => ($isModal ? "0" : "calc(60px - 2rem)")};
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
`;
export const CardLessContentContainer = ({
  children,
  label = "Log in to your account",
  $isModal = false,
}: {
  children: ReactNode;
  label?: string;
  $isModal: boolean;
}) => {
  return (
    <>
      <CardlessHeading $isModal={$isModal}>{label}</CardlessHeading>
      {children}
    </>
  );
};

const StyledLinkNext = styled(LinkNext)`
  color: ${colors.periwinkleShade1};

  :hover {
    color: ${colors.periwinkleTint1};
    text-decoration: none;
  }
`;

export interface LoginProps {
  next?: string;
  fullScreen?: boolean;
  isModal?: boolean;
  useCard?: boolean;
  signInComponent?: ReactNode;
  subheadComponent?: ReactNode;
  email?: string;
}

const Login = ({
  next = DEFAULT_NEXT,
  fullScreen = false,
  isModal = false,
  useCard = true,
  email,
  signInComponent,
  subheadComponent,
}: LoginProps) => {
  const { loginWithSession, isAuthenticated } = useContext(AuthContext);

  const [hideLoginForm, setHideLoginForm] = useState<boolean>(false);

  const router = useRouter();
  // If email exists in main url use that instead
  const loginEmail =
    router.query.loginEmail || router.query.loginemail || email || "";

  const routeNext = useCallback(() => router.push(next), [next]);
  const routeToSubscribe = () => {
    if (fullScreen || isModal || !useCard) {
      router.push({ pathname: "/signup", query: router.query });
    } else {
      router.push("/subscribe/");
    }
  };

  // redirect if authenticated
  useEffect(() => {
    if (isAuthenticated) {
      routeNext();
    }
  }, [routeNext, isAuthenticated]);

  const onConnectAccountCancel = () => setHideLoginForm(false);

  const onConnectAccountForm = () => setHideLoginForm(true);

  const ContentContainer = useCard
    ? CustomCardContainer
    : CardLessContentContainer;

  return (
    <>
      <Head>
        <meta name="robots" content="noindex" />
      </Head>
      <MainContainer $fullScreen={fullScreen} $isModal={isModal}>
        <div className={"content-spacing"}>
          <Container>
            <ContentContainer $isModal={isModal}>
              {subheadComponent || null}
              {!hideLoginForm ? (
                <>
                  <LoginForm
                    onSubmit={loginWithSession}
                    onSuccess={routeNext}
                    initialValues={{ login: loginEmail }}
                  />
                  <a
                    className={
                      "text-perriwinkle text-sm font-bold block text-center w-full mt-4"
                    }
                    href={makeUrl(LINKS.resetPassword)}
                  >
                    Forgot Password?
                  </a>
                  <Divider or />
                </>
              ) : null}

              <SocialButtonsContainer
                onConnectAccountForm={onConnectAccountForm}
                onConnectAccountCancel={onConnectAccountCancel}
                disableAutoTap={isModal}
                width={324}
              />
              <div
                className={
                  "form-toggle text-textGray-darker my-6 text-sm md:mb-3 text-center"
                }
              >
                {signInComponent || (
                  <>
                    New to Literati?{" "}
                    <button
                      type={"button"}
                      className={"text-perriwinkle font-bold"}
                      onClick={routeToSubscribe}
                      onMouseDown={routeToSubscribe}
                    >
                      Sign Up
                    </button>
                  </>
                )}
              </div>
            </ContentContainer>
          </Container>
        </div>
        <GlobalStyle />
      </MainContainer>
    </>
  );
};

export const LoginLayout = ({
  next,
  pageTitle,
  child,
  ...props
}: {
  next: string;
  pageTitle: string;
  signInComponent?: ReactNode;
  subheadComponent?: ReactNode;
  child: (props: React.ComponentPropsWithoutRef<typeof Login>) => ReactNode;
}) => {
  if (next) {
    let isModal = false;
    const isOAuth = next.includes("//auth.");
    const isClassroom = next.startsWith(LINKS.literatiExtraCredit);
    const isActivation = next.startsWith(LINKS.activation);
    if (isOAuth || isClassroom || isActivation) {
      try {
        const redirect = new URL(next);
        isModal = Boolean(redirect.searchParams.get("modal"));
      } finally {
        return (
          <StickyHeaderLayout pageTitle={pageTitle} hideBackButton={true}>
            {child({ useCard: false, isModal, ...props })}
          </StickyHeaderLayout>
        );
      }
    }
  }

  return (
    <Layout pageTitle={pageTitle} noContainer>
      {child({ next, ...props })}
    </Layout>
  );
};

type ServerSideProps = Extract<
  Awaited<ReturnType<typeof getServerSideProps>>,
  { props: object }
>["props"];

const LoginPage = (props: ServerSideProps) => {
  const router = useRouter();
  let pageTitle = "Log In";
  let signInComponent: ReactNode = null;
  let subheadComponent: ReactNode = null;

  const next = String(props?.next);

  if (next?.startsWith(LINKS.literatiExtraCredit)) {
    pageTitle = "Login to your classroom account";
    signInComponent = (
      <StyledLinkNext href={next.toString()} legacyBehavior={false}>
        New to Extra Credit?
      </StyledLinkNext>
    );
  }

  if (next?.startsWith(LINKS.activation)) {
    // don't show signup link
    signInComponent = " ";
    subheadComponent = (
      <SubheadText>
        You’ll need to use the same account you used to purchase your
        subscription.
      </SubheadText>
    );
  }

  let nextURL = null;
  try {
    nextURL = new URL(next || "");
  } catch {}

  // if the next query param has signup=true, then redirect to signup
  if (nextURL && Boolean(nextURL.searchParams.get("signup"))) {
    // need to remove signup query parameter to ensure that this does not
    // disrupt subsequent flows.
    nextURL.searchParams.delete("signup");
    router.replace({
      pathname: "/signup",
      // nextjs should encode the nexturl for us!
      query: { ...router.query, next: nextURL.toString() },
    });
  }

  const email = nextURL?.searchParams.get("loginEmail") || "";

  return (
    <LoginLayout
      pageTitle={pageTitle}
      next={next}
      signInComponent={signInComponent}
      subheadComponent={subheadComponent}
      child={({ useCard, isModal, ...props }) => (
        <Login
          {...props}
          useCard={useCard}
          isModal={isModal}
          next={next}
          email={email}
        />
      )}
    />
  );
};

export const getServerSideProps = async (ctx: GetServerSidePropsContext) => {
  const auth = await getAuth(ctx);
  const {
    query: { next = DEFAULT_NEXT },
  } = ctx;

  const path = sanitizeRedirectPath(
    decodeURIComponent(String(next) || "")
  ) || DEFAULT_NEXT;

  if (auth.isAuthenticated) {
    return {
      redirect: {
        destination: path,
        permanent: false,
      },
    };
  }

  return {
    props: {
      next: path,
    },
  };
};

export default LoginPage;
